import React, { useEffect } from "react";
import "./EventContainer.css";
import "aos/dist/aos.css";
import shareImg from "../../../assets/share.png";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { greyColor, redColor } from "../../../color";
import moment from "moment";
import Aos from "aos";
const dateFormat = "MMM D, YYYY";

const EventContainer = (props) => {
  const { event, onEventsDetailClick, index, onRegisterNow } = props;
  const cutDescriptionIfNecessary = (description) => {
    const maxLines = 2;
    const maxCharactersPerLine = 200;

    const lines = description.split("\n");
    let truncatedDescription = "";

    for (let i = 0; i < Math.min(maxLines, lines.length); i++) {
      if (lines[i].length > maxCharactersPerLine) {
        truncatedDescription += lines[i].slice(0, maxCharactersPerLine) + "...";
      } else {
        truncatedDescription += lines[i];
      }

      if (i < maxLines - 1) {
        truncatedDescription += "\n";
      }
    }

    if (lines.length > maxLines) {
      truncatedDescription += "...";
    }

    return truncatedDescription;
  };

  useEffect(() => {
    Aos.init({
      duration: 2000,
      once: true,
    });
  }, []);
  return (
    <>
      <Box display="flex" flexDirection="column" m={2} position="relative">
        <Box data-aos={`fade-down-${index}`} data-aos-easing="linear">
          <Box className="share-event-image">
            <img
              src={shareImg}
              onClick={() => props.onShareImageClick(event.urlEventPage)}
              alt=""
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Box width="100%" height="300px">
            <img className="imgStyles" src={event.eventLogo} alt="" />
          </Box>
        </Box>
        <Box
          width="90%"
          alignSelf="center"
          marginTop="-30px"
          data-aos="fade-up"
        >
          <Card
            style={{
              borderRadius: "5px",
              background: greyColor,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <CardContent>
              <Box
                color={redColor}
                fontFamily="Segoe UI"
                fontWeight={700}
                fontSize={14}
                mb={2}
                height="10px"
              >
                <Typography gutterBottom variant="paragraph">
                  {moment(event.startDate).format(dateFormat)} -{" "}
                  {moment(event.endDate).format(dateFormat)}
                </Typography>
              </Box>

              <Box height="220px" borderBottom="1px solid #dcdcdc">
              <Box
      fontFamily="Segoe UI"
      fontWeight={700}
      fontSize={20}
      mb={2}
      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
    >
      {event.title}
      {event.title_red && (
        <>
          {" - "}
          <span style={{ color: "#e00000" }}>{event.title_red}</span>
        </>
      )}
    </Box>
                <Box
                  fontFamily="Segoe UI"
                  fontWeight={500}
                  fontSize={14}
                  mb={2}
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {cutDescriptionIfNecessary(event.description)}
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent={["center", "center", "flex-end"]}
                flexDirection={["column", "column", "row"]}
                mb={-1.5}
                data-aos={`fade-left-${index}`}
                data-aos-offset="0"
                data-aos-easing="ease-in-back"
              >
                <Box mr={[0, 0, 1, 1]}>
                  <Button
                    size="small"
                    className="btnStyles"
                    data-aos-easing="ease-in-back"
                    data-aos={`fade-down-${index}`}
                    data-aos-offset="-200"
                    onClick={() => onEventsDetailClick(event)}
                  >
                    Event Details
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="small"
                    className="btnStyles"
                    data-aos-easing="ease-in-back"
                    data-aos={`fade-down-${index}`}
                    data-aos-offset="-200"
                    onClick={() => onRegisterNow()}
                  >
                    Join Event
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default EventContainer;
