import React from "react";
import { withRouter } from "react-router-dom";
import EventContainer from "../EvetnsContainer/EventContainer";
import { Grid } from "@material-ui/core";

class EventsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeQueryParam: false,
    };
  }
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const isCarousel = params.get("type") === "carosuel";
    this.setState({ typeQueryParam: isCarousel });
  }

  onEventDetailClick = (event) => {
    window.top.location.href = `http://www.motorevents.com.au/index.php/members-area/#/events/${event.id}`;
  };
  onShareImageClick = (eventShareLink) => {
    window.open(eventShareLink);
  };
  registerNow = () => {
    window.open(`https://www.motorevents.com.au/index.php/members-area/`);
  };
  cutDescriptionIfNecessary = (description) => {
    const maxLines = 2;
    const maxCharactersPerLine = 200;

    const lines = description.split("\n");
    let truncatedDescription = "";

    for (let i = 0; i < Math.min(maxLines, lines.length); i++) {
      if (lines[i].length > maxCharactersPerLine) {
        truncatedDescription += lines[i].slice(0, maxCharactersPerLine) + "...";
      } else {
        truncatedDescription += lines[i];
      }

      if (i < maxLines - 1) {
        truncatedDescription += "\n";
      }
    }

    if (lines.length > maxLines) {
      truncatedDescription += "...";
    }

    return truncatedDescription;
  };

  render() {
    const { events } = this.props;
    return (
      <>

          <div
          style={{ height: "100%", overflow: "hidden" }}
          className="eventsListReactApp"
          >
            <Grid container spacing={1}>
              {events.map((event,index) => (
                <Grid item key={event.id} xs={12} sm={6} md={4} lg={4}>
                  <EventContainer
                    event={event}
                    onEventsDetailClick={this.onEventDetailClick}
                    onShareImageClick={this.onShareImageClick}
                    onRegisterNow={this.registerNow}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        
      </>
    );
  }
}

export default withRouter(EventsList);
