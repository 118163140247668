import React, { Component } from "react";
import "./App.css";
import EventsList from "./components/Events/EventsList/EventsList";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "aos/dist/aos.css";
import axios from "axios";
const baseUrl = "https://api.motorevents.com.au/api";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      typeQueryParam: false,
    };
  }

  componentDidMount() {
    axios({
      method: "get",
      url: baseUrl + "/comingEvents",
    })
      .then((res) => {
        this.setState({
          events: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { events } = this.state;
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" render={(props) => <EventsList events={events} />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
